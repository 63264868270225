import SimpleParallax from 'simple-parallax-js';

export default {
    mounted(el, binding) {
        const scale = Math.abs(binding.value);
        const orientation = binding.value > 0 ? 'up' : 'down';

        if (window.outerWidth > 640) {
            new SimpleParallax(el, {
                scale,
                orientation,
                overflow: true,
            });
        }
    },
};
