// Remove our preload class from the body, preventing early CSS transitions
import '@utils/preload';

// CSS needs to be imported here as it's treated as a module
import '@/css/style.pcss';

// ================================================
// All third-party, globally-required libraries should be loaded here
// ================================================

// Support for lazyload images (and bg images)
import 'lazysizes';
import 'lazysizes/plugins/unveilhooks/ls.unveilhooks';
import 'lazysizes/plugins/bgset/ls.bgset';


// ================================================
// Setup Vue3 - Use only if this project needs Vue!
// ================================================
import { createApp, defineAsyncComponent } from 'vue';
import apolloProvider from './apollo';

// Animate on scroll
import AOS from 'aos';

// Components
import Rot13Email from './components/Rot13Email.vue';
const BlogPosts = defineAsyncComponent(() => import('./components/BlogPosts.vue'));

// Directives
import parallax from './directives/parallax';

// Import Swiper Vue.js components
import { Swiper, SwiperSlide } from 'swiper/vue';
import {
    Autoplay,
    Pagination,
    Lazy,
} from 'swiper';

// Collapse transition
import CollapseTransition from '@ivanv/vue-collapse-transition/src/CollapseTransition.vue';

const app = createApp({
    delimiters: [ '${', '}' ],

    components: {
        Swiper,
        SwiperSlide,
    },

    directives: {
        parallax,
    },

    data() {
        // const loaded = false;
        const loaded = !!sessionStorage.loaded;

        return {
            loaded,
            preloaded: loaded,
            isMenuOpen: false,
            swiperModules: [ Autoplay, Pagination, Lazy ],
        };
    },

    mounted() {
        if (!this.loaded) {
            sessionStorage.loaded = 'true';

            const takeTimeA = Math.max(0, 100 - (Date.now() - window.loadTime));
            const takeTimeB = Math.max(0, 1500 - (Date.now() - window.loadTime));

            if (document.readyState !== 'complete') {
                window.addEventListener('load', () => {
                    setTimeout(() => {
                        this.preloaded = true;
                    }, takeTimeA);

                    setTimeout(() => {
                        this.loaded = true;

                        setTimeout(() => {
                            AOS.init({
                                duration: 1200,
                            });
                        }, 100);
                    }, takeTimeB);
                });
            } else {
                setTimeout(() => {
                    this.preloaded = true;
                }, takeTimeA);

                setTimeout(() => {
                    this.loaded = true;

                    setTimeout(() => {
                        AOS.init({
                            duration: 1200,
                        });
                    }, 100);
                }, takeTimeB);
            }
        } else {
            setTimeout(() => {
                AOS.init({
                    duration: 1200,
                });
            }, 300);
        }
    },

    methods: {
        onToggleMenu() {
            this.isMenuOpen = !this.isMenuOpen;
        },
    },
});

app.component('Rot13Email', Rot13Email);
app.component('BlogPosts', BlogPosts);
app.component('CollapseTransition', CollapseTransition);

app.use(apolloProvider);

app.mount('#app');
